<template>
  <div class="ma-n5">
    <v-img src="../../assets/images/background/mountainBackground.png" height="450px"> </v-img>
    <div class="text-center d-flex flex-column align-center justify-center dashboard-container">
      <div class="dashboard-wrapper mt-8">
        <h1 class="mb-4 font-weight-black dashboard-title">
          {{ $t('content.welcome') }}
        </h1>
        <h3 class="dashboard-body">
          {{ $t('content.dashboard') }}
        </h3>
        <div class="dashBox">
          <div class="text-left">
            <h3 class="font-weight-bold">
              {{ $t('content.feature') }}
            </h3>
            <div class="mt-3">
              <div class="circle">1</div>
              {{ $t('title.dashboard') }}
            </div>
            <div class="mt-3">
              <div class="circle">2</div>
              {{ $t('title.deliverable_center') }}
            </div>
            <div class="mt-3">
              <div class="circle">3</div>
              {{ $t('title.sample_tracking') }}
            </div>
            <div class="mt-3">
              <div class="circle">4</div>
              {{ $t('title.result_management') }}
            </div>
            <div class="mt-3">
              <div class="circle">5</div>
              {{ $t('title.invoice') }}
            </div>
            <div class="mt-3">
              <div class="circle">6</div>
              {{ $t('title.feedback') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
};
</script>

<style lang="scss" scoped>
.dashboard {
  &-container {
    box-sizing: border-box;
    // height: calc(100vh - 100px);
    padding: 0px 20px 20px 20px;
  }
  &-title {
    color: #0060af;
  }
  &-body {
    color: #16325c;
    opacity: 0.5;
  }
  &-wrapper {
    max-width: 800px;
  }
}
.dashBox {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  background-color: #ebf6ff;
  color: #0060af;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 20px;
  max-width: 500px;
}
.circle {
  background-color: #addaff;
  border-radius: 100%;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}
</style>
